/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { SportDataTeam } from '../models/SportDataTeam';
import type { SportDataTeamSelection } from '../models/SportDataTeamSelection';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class TeamService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Get team selection
     * Get team selection
     * @returns SportDataTeamSelection Success
     * @throws ApiError
     */
    public getTeamSelectionOptaTeam({
        id,
    }: {
        /**
         * OPTA ID of the requested team
         */
        id: string,
    }): CancelablePromise<SportDataTeamSelection> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/football/team/{id}/selection',
            path: {
                'id': id,
            },
        });
    }
    /**
     * Get team details
     * Get team details
     * @returns SportDataTeam Success
     * @throws ApiError
     */
    public getByIdOptaTeam({
        id,
    }: {
        /**
         * OPTA ID of the requested team
         */
        id: string,
    }): CancelablePromise<SportDataTeam> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/football/team/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * Get team export for the magazine
     * Get team export for the magazine
     * @returns any Success
     * @throws ApiError
     */
    public getTeamExportOptaTeam({
        id,
        playerId,
    }: {
        /**
         * OPTA ID of the requested team
         */
        id: string,
        /**
         * OPTA ID of the requested player
         */
        playerId?: string,
    }): CancelablePromise<Record<string, any>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/football/team/{id}/export',
            path: {
                'id': id,
            },
            query: {
                'playerId': playerId,
            },
        });
    }
}
